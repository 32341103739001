import {addClass, findClosest, removeClass, toggleAttribute, toggleClass} from '../../assets/js/utility';

class Header {
  constructor() {
    this.element = document.querySelector(".section-site-header");
    this.navbar = document.querySelector(".section-site-header .dynamic-nav");
    this.menuIcon = document.querySelector("#mobile-menu-icon");
  }

  initNavbar(sections) {
    if (this.element && this.navbar) {
      this.headerHeight = this.element.offsetHeight;
      this.halfHeight = this.headerHeight / 2;
      this.themeChangeSections = sections;   // sections requiring frosted treatment

      this.changeTheme.call(this);
      window.addEventListener("resize", this.changeTheme.bind(this));
      window.addEventListener("scroll", this.changeTheme.bind(this));
      window.addEventListener("scroll", this.shrinkOnScroll.bind(this));
    }

    if (this.menuIcon) {
      this.menuIcon.addEventListener("click", () => {
        toggleClass(this.menuIcon, "open");
        toggleAttribute(this.menuIcon, "aria-expanded");
      })
    }
  }

  /**
   * Changes the header theme when over certain components (eg: hero js)
   * Component class names from registerThemeColor() list
   * Section requres full selector, Classes is as would appear in the html
   */
  changeTheme() {
    const BREAKPOINT_MD = 886;

    // if using mobile nav, don't change themes
    if (window.innerWidth >= BREAKPOINT_MD) {
      const sectionClasses = this.themeChangeSections.map(( {section} ) => section);

      let sectionElements = document.querySelectorAll(sectionClasses.join(', '));
      let navIsOverTheme = false;

      for (let i = 0; i < sectionElements.length; i++) 
      {
        let component = sectionElements[i];
        let top = component.getBoundingClientRect().top;
        let bottom = component.getBoundingClientRect().bottom;
        let componentHeight = component.offsetHeight;
        
        // find matching theme values in object array
        let themeClasses = this.checkComponentTheme(component);

        // skip if darkbackground isn't underneath the top component
        if ( top > this.headerHeight && bottom > (componentHeight + this.headerHeight)
          || bottom < 0
        ) {
          continue;
        }
        
        navIsOverTheme = true;

        //check whether scroll still inside component boundaries
        let beyondCurrentSectionTop = top >= 0 && top <= this.headerHeight && top > this.halfHeight;
        let beyondCurrentSectionBottom = bottom >= 0 && bottom <= this.halfHeight;

        // is the component in an animation wrapper? If so, use for next/prev comparisons
        let parentOfComponent = findClosest(component, ".pin-spacer");
        component = parentOfComponent != null
          ? parentOfComponent
          : component;

        //check section siblings, minding animation wrappers
        let nextSection, prevSection;

        if (component.nextElementSibling) {
          nextSection = component.nextElementSibling.classList.contains('pin-spacer')
            ? component.nextElementSibling.firstElementChild
            : component.nextElementSibling;
        }
        
        if (component.previousElementSibling) {
          prevSection = component.previousElementSibling.classList.contains('pin-spacer')
            ? component.previousElementSibling.firstElementChild
            : component.previousElementSibling;
        }

        let isNextSiblingThemed = nextSection == sectionElements[i + 1];
        let isPrevSiblingThemed = prevSection == sectionElements[i - 1];

        // split class list into array for assignment
        themeClasses = themeClasses.split(" ");

        // see if sibling is a different theme
        let isNextSiblingSameTheme = true, isPrevSiblingSameTheme = true;
        let nextClasses = "", prevClasses = "";

        for (let i = 0; i < themeClasses.length; i++) {
          if (nextSection && !this.checkComponentTheme(nextSection).includes(themeClasses[i]))
          {
            isNextSiblingSameTheme = false;
            nextClasses = this.checkComponentTheme(nextSection).split(" ");
            break;
          }

          if (prevSection && !this.checkComponentTheme(prevSection).includes(themeClasses[i]))
          {
            isPrevSiblingSameTheme = false;
            prevClasses = this.checkComponentTheme(prevSection).split(" ");
            break;
          }
        }

        if ( beyondCurrentSectionBottom && !isNextSiblingThemed
          || beyondCurrentSectionTop && !isPrevSiblingThemed
        ) {
          themeClasses.forEach(e => removeClass(this.element, e));
        } 
        // pre-emptively add next/prev classes to avoid stutter
        else if ( beyondCurrentSectionBottom && isNextSiblingThemed && !isNextSiblingSameTheme ) {
          themeClasses.forEach(e => removeClass(this.element, e));

          nextClasses.forEach(e => addClass(this.element, e));
        } 
        else if ( beyondCurrentSectionTop && isPrevSiblingThemed && !isPrevSiblingSameTheme ) {
          themeClasses.forEach(e => removeClass(this.element, e));
          prevClasses.forEach(e => addClass(this.element, e));
        }
        else 
        {
          if (nextClasses) nextClasses.forEach(e => removeClass(this.element, e));
          if (prevClasses) prevClasses.forEach(e => removeClass(this.element, e));

          themeClasses.forEach(e => addClass(this.element, e));
        }

        //break once found current component
        break;
      }

      // fallback, remove all themes
      if (!navIsOverTheme) {
        removeClass(this.element, "theme-frosted-white");
        removeClass(this.element, "theme-frosted-primary");
        removeClass(this.element, "transparent");
      }
    }
    else {
      // remove all possible theme classes in case resize
      removeClass(this.element, "theme-frosted-white");
      removeClass(this.element, "theme-frosted-primary");
      removeClass(this.element, "transparent");
    }
  }

  // checks if themes are in component list
  // returns an array of class names
  checkComponentTheme(component) {
    if (component) {
      for(let i = 0; i < this.themeChangeSections.length; i++) {
        let eSection = this.themeChangeSections[i].section.replace(".", "");
  
        if (component.classList.contains(eSection))
        {
          return this.themeChangeSections[i].classes;
        }
      };
  
      return "";
    }
  }

  /**
   * Shrinks header height if user scrolls on the page.
   * Uses scss utility classes to adjust header & the <main> element, since it has
   * negative margin on it to allow hero components to be flush with the top
   */
  shrinkOnScroll() {
    const mainElement = document.querySelector("main");
    const breakpoint = 886; // as per scss variables

    // do nothing if device small enough for mobile styles to trigger
    if (mainElement && window.innerWidth >= breakpoint) 
    {
      if (document.body.scrollTop > this.halfHeight || document.documentElement.scrollTop > this.halfHeight) 
      {
        addClass(this.element, 'half-height');
        addClass(this.element, 'mb-n-xl');
      } 
      else 
      {
        // reset
        removeClass(this.element, 'half-height');
        removeClass(this.element, 'mb-n-xl');
      }
    }
  }
}

let headerObj = new Header();

export default headerObj;