class ProjectMap {
  constructor() {
    this.mapContainer = document.querySelector('#projectMap');
    this.locationsFilePath = '/assets/datasources/map-locations.json';
    this.map;

    if (this.mapContainer) {
      this.initMap();
      this.getMapLocations();
    }
  }

  async initMap() {
    const { Map } = await google.maps.importLibrary('maps');

    this.map = new Map(this.mapContainer, {
      mapId: 'b099d3f6ebf0fca3',
      center: { lat: 56, lng: -116 },
      scaleControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoom: 5,
      minZoom: 5,
      maxZoom: 7,
      restriction: {
        latLngBounds: {
          north: 68,
          south: 43,
          east: -70,
          west: -140,
        },
      },
    });
  }

  getMapLocations() {
    fetch(this.locationsFilePath)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((locations) => this.createCustomMarkers(locations))
      .catch((err) => {
        console.log(err);
      });
  }

  async createCustomMarkers(locations) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

    locations.forEach(([position, title, image, projectLink], i) => {
      const markerContent = document.createElement('div');
      markerContent.classList.add('marker-project');
      markerContent.innerHTML =
        '<svg class="map-pin" width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50" fill="#2c438e" fill-opacity="0.9" /></svg>' +
        '<div class="marker-project-close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><line x1="18" x2="6" y1="6" y2="18" /><line x1="6" x2="18" y1="6" y2="18" /></svg></div>' +
        `<a class="marker-project-info" href="${projectLink}">` +
        `<img src="${image}">` +
        `<h6>${title}</h6>` +
        '</a>';

      const marker = new AdvancedMarkerElement({
        map: this.map,
        position,
        content: markerContent,
        title,
        gmpClickable: true,
      });

      // Add a click listener for each marker, and set up the info window.
      // not using info windows cause adding custom properties to markers
      // appears to be legacy only -- so cannot achieve same effect as previous
      marker.addListener('click', () => {
        if (marker.content.classList.contains('is-open')) {
          marker.content.classList.remove('is-open');
          marker.zIndex = null;
        } else {
          marker.content.classList.add('is-open');
          marker.zIndex = 1;
        }
      });
    });
  }

  registerThemeColor() {
    return {
      section: '.section-project-map',
      classes: 'theme-frosted-primary transparent',
    };
  }
}

export default ProjectMap;
