class WaterfallFeatureImages {
  constructor() {
    this.waterfallFeatureSection = document.querySelector(".section-waterfall-feature-images");

    if (this.waterfallFeatureSection) {
      this.waterfallAnimation();
    }
  }

  waterfallAnimation() {
    // don't animate on smaller devices
    const BREAKPOINT_MD = 886; // as per scss variables

    if (window.innerWidth >= BREAKPOINT_MD)
    {
      let content = this.waterfallFeatureSection.querySelector(".dynamic-grid-layout");
      gsap.utils.toArray(".section-waterfall-feature-images .grid-item").forEach((gridItem, i) => {
        gsap.fromTo(gridItem, { 
          yPercent: -100 - (i % 2 * 40)
        }, {
          scrollTrigger: {
            trigger: this.waterfallFeatureSection,
            start: "top center-=10%",
            toggleActions: "play none none reverse",
            markers: false
          },
          yPercent: 0
        });
      });

      gsap.fromTo(content, 
      { opacity: 0 }, 
      { 
        scrollTrigger: {
          trigger: this.waterfallFeatureSection,
          start: "top center-=10%",
          toggleActions: "play none none reverse",
        },
        opacity: 1
      });
    }
  }

  registerThemeColor() {
    return { section: ".section-waterfall-feature-images", classes: "theme-frosted-primary"};
  }
}

export default WaterfallFeatureImages;