import {addClass, removeClass} from "../../assets/js/utility";

class ScrollIndicatorBar {
  constructor() {
    this.indicatorBar = document.querySelector(".simple-scroll-indicator-bar");
    this.indicator = document.querySelector("#scroll-indicator");
    this.headerElement = document.querySelector(".section-site-header");

    if (this.indicator) {
      document.addEventListener("DOMContentLoaded", this.checkTheme.bind(this));
      window.addEventListener("scroll", this.grow.bind(this));
    }
  }

    
  grow() {
    const minHeight = 5; // default height set in scss
    let newHeight = minHeight;

    let scrollPercentage = (100 * window.scrollY) / (document.body.scrollHeight - window.innerHeight);

    if (scrollPercentage > minHeight) {
      newHeight = scrollPercentage;
    }

    this.indicator.style.height = newHeight + "%";
    
    // check header if theme change needed
    this.checkTheme();
  }

  checkTheme() {
    if (this.headerElement) {
      let frostedTheme = "theme-frosted-white";

      if(this.headerElement.classList.contains(frostedTheme)) {
        addClass(this.indicatorBar, frostedTheme);
      }
      else {
        removeClass(this.indicatorBar, frostedTheme);
      }
    }
  }
}

export default ScrollIndicatorBar;