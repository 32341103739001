import {findClosest, wrapElement} from '../../assets/js/utility';

class ResponsiveTables {
    constructor() {
        // May want to tweak if any tables are used for non-data purposes in project
        this.tablesList = document.querySelectorAll('table');

        if (this.tablesList) {
            for (let i = 0; i < this.tablesList.length; i++) {
                // avoid double wrapping
                if (this.tablesList[i].parentElement.classList.contains('table-wrapper')) {
                    continue;
                }

                wrapElement(this.tablesList[i], document.createElement('div'), "table-wrapper");
                this.setLabelColumn(this.tablesList[i]);
            }
        }
    }
    
    // assigns a set width for first column for wrapper shadow
    // based on auto-fit size determined on load or hardset width if too big
    setLabelColumn(el) {
        let width = 0;
        let largestLabelWidth = 190;    // in px
        let rows = el.querySelectorAll('tr');
        
        for (let i = 0; i < rows.length; i++) {
            let tdFirst = rows[i].querySelector('td');

            if (tdFirst) {
                // special case: check if only 2 columns
                if (rows[i].cells.length === 2 && tdFirst.getAttribute('colspan') === null) {
                    for (let c = 0; c < rows.length; c++) {
                        rows[c].querySelector('td').style.position = "relative";
                        rows[c].querySelector('td').style.backgroundColor = "transparent";
                    }

                    return;
                }

                if (tdFirst.getAttribute('colspan') == null) {
                    width = tdFirst.clientWidth <= largestLabelWidth ? tdFirst.clientWidth + "px" : largestLabelWidth + "px";

                    tdFirst.style.minWidth = width;

                    let wrapper = findClosest(tdFirst, '.table-wrapper');
                    wrapper.style.backgroundPosition =  width + " center, right center, " + width + " center, right center";

                    return;
                }
            }
        }
    };
}

export default ResponsiveTables;