// Class List Manipulations
export const addClass = (el, cssClass) => {
    if (!el.classList.contains(cssClass)) {
        el.classList.add(cssClass);
        return true;
    }
    return false;
};

export const removeClass = (el, cssClass) => {
    if (el.classList.contains(cssClass)) {
      el.classList.remove(cssClass);
      return true;
    }
    return false;
};

export const toggleClass = (el, cssClass) => {
    addClass(el, cssClass) || removeClass(el, cssClass);
};

export const toggleClasses = (el, classList) => {
    for (let c = 0; c < classList.length; c++) {
        addClass(el, classList[c]) || removeClass(el, classList[c]);
    }
};

export const wrapElement = (el, wrapper, wrapperClass) => {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    addClass(wrapper, wrapperClass);
};

// Toggle binary
export const toggleAttribute = (el, attr) => {
    let currentValue = el.getAttribute(attr);

    if (currentValue == 'true') {
        el.setAttribute(attr, "false");
        return true;
    } else if (currentValue == 'false') {
        el.setAttribute(attr, "true");
        return true;
    }
    
    return false;
};

// Find Closest & return that element
export const findClosest = (child, find) => {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
          Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;
        
            do {
            if (Element.prototype.matches.call(el, s))
            {
                return el;
            }
        
            el = el.parentElement || el.parentNode;
            }
            while (el !== null && el.nodeType === 1);
        
            return null;
        };
    }

    if (child) {
        return child.closest(find);
    }

    return false;
};
