class FullscreenAnimated {
  constructor() {
    this.introContainer = document.querySelector(".animate-intro-separate-ways");
    this.stackedBarsContainer = document.querySelector(".animate-shift-stack");
    this.pinSectorsContainer = document.querySelector(".animate-pin-sector-list");

    // don't animate on smaller devices
    const BREAKPOINT = 1077; // as per scss variables

    if (window.innerWidth >= BREAKPOINT)
    {
      if (this.introContainer) {
        this.introSeparateWaysAnimation();
      }

      if (this.stackedBarsContainer) {
        this.shiftStackAnimation();
      }

      if (this.pinSectorsContainer) {
        this.pinSectorsAnimation();
      }
    }
  }

  // Initial home page section animation
  introSeparateWaysAnimation() {
    let title = this.introContainer.querySelector("h1");
    let leftHalf = this.introContainer.querySelector(".half-left");
    let transitionRightHalf = this.introContainer.querySelector(".half-right");

    gsap.to(title, {
      scrollTrigger: {
        trigger: this.introContainer.parentElement,
        start: "20% 20%",
        end: "40% 20%",
        pin: true,
        scrub: true,
        toggleActions: "play none none reverse",
        markers: false,
      },
      opacity: 0,
      yPercent: -105
    });

    // Timeline for halves split & slide up next section
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.introContainer.parentElement,
        start: "center center-=20%",
        toggleActions: "play none none reverse",
        markers: false,
      }
    });

    tl.to(leftHalf, { yPercent: 200, opacity: 0, ease: "back.in(1)" })
      .to(transitionRightHalf, { width: 0, opacity: 0, ease: "power3.in" }, "<+0.3")
      .to(leftHalf, { maxHeight: 0, duration: 0.1 }, ">")   // hide element on page scroll
  }

  // Transition from About Us to Sectors
  shiftStackAnimation() {
    let stickyLeft = this.stackedBarsContainer.querySelector(".half-left");
    let stickyLeftContent = stickyLeft.querySelector(".half-wrapper");

    // for animating up next content section
    let nextSection = this.stackedBarsContainer.parentElement.nextElementSibling;
    if (!nextSection) return false;

    let transitionRightHalf = nextSection.querySelector(".half-right");
    let nextLeftHalf = nextSection.querySelector(".half-left");
    if (!transitionRightHalf || !nextLeftHalf) return false;

    // positional functions for bar stack
    let scaleBarSize = () => transitionRightHalf.getBoundingClientRect().height / stickyLeftContent.getBoundingClientRect().height;

    /**
     * Calculates the change in position for the bars to avoid gaps/overlap based 
     * on the difference of size between the two transitioning elements and what 
     * numbered bar it is.
     * eg: 1st bar moves 10%, 2nd bar has to move 20% to close the gap, 3rd is 30%, etc.
     * @param {int} index 
     * @returns {double} y position for the bar
     */
    let shiftBarsAfterAnimation = (index) => {
      let leftHeight = stickyLeftContent.getBoundingClientRect().height;
      let rightHeight = transitionRightHalf.getBoundingClientRect().height;
      let difference = leftHeight - rightHeight;
      const NUM_BARS = 10;  // should match the number of animated bars

      return 0 - difference / NUM_BARS * (index + 1);
    };

    // necessary or bars will be in a separate stack context (no height)
    gsap.set(stickyLeft, {
      x: 0, 
      y: 0,
      height: (i, self) => self.getBoundingClientRect().height,
    });

    // align bg bar stack with content element
    gsap.utils.toArray(".bar").forEach((bar, i) => {
      gsap.set(bar, { 
        y: stickyLeftContent.getBoundingClientRect().height * -1, 
        scrollTrigger: { invalidateOnRefresh: true }
      })
    });

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: stickyLeft,
        start: "top+=10% center",
        end: `bottom center`,
        toggleActions: "play none none reverse",
        scrub: 1.5,
        invalidateOnRefresh: true,
        markers: false
      }
    });

    tl.fromTo(stickyLeftContent, 
      { opacity: 1 }, 
      { duration: 0.3, opacity: 0}, "<")
    .to(".bar", {
      duration: 0.5,
      xPercent: 100,
      transformOrigin: "0 100%",
      scaleY: scaleBarSize,
      y: (index) => shiftBarsAfterAnimation(index),
      ease: "power1.in",
      stagger: { amount: 0.5 }
    }, "<")
    .to(stickyLeft, { opacity: 0 }, ">");
  }

  // Sectors List 
  pinSectorsAnimation() {
    let rightHalf = this.pinSectorsContainer.querySelector(".half-right");
    let leftHalf = this.pinSectorsContainer.querySelector(".half-left");
    let leftTopContent = leftHalf.querySelector(".half-wrapper > div:first-child");

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.pinSectorsContainer,
        start: `top-=13% center`,
        end: "top center",
        toggleActions: "play none none reverse",
        markers: false
      }
    });

    tl.fromTo(rightHalf, 
      { opacity: 0 },
      { opacity: 1 })
    .fromTo(leftHalf, {
      opacity: 0,
      yPercent: 50
    }, { 
      opacity: 1,
      yPercent: 0,
      y: () => leftTopContent.offsetHeight
    }, ">-0.4");

    // pin right half on scroll within section
    gsap.to(rightHalf, {
      scrollTrigger: {
        trigger: rightHalf,
        start: "bottom bottom",
        endTrigger: this.pinSectorsContainer,
        end: "bottom bottom",
        pin: true,
        scrub: true,
        markers: false
      }
    });

    // undo initial offset to avoid content clipping at bottom
    gsap.fromTo(leftHalf, {
      y: () => leftTopContent.offsetHeight
    }, {
      scrollTrigger: {
        trigger: this.pinSectorsContainer,
        start: "top center",
        end: "bottom bottom",
        scrub: true,
        toggleActions: "play none none reverse",
        markers: false,
      },
      y: 0
    });
    
    // fade on scroll up
    gsap.to(leftTopContent, {
      scrollTrigger: {
        trigger: this.pinSectorsContainer,
        start: "top+=20% center",
        end: "center center",
        scrub: true,
        toggleActions: "play none none reverse",
        markers: false,
      },
      opacity: 0
    });
  }

  // must use section classes for header theming to work
  registerThemeColor() {
    let sectionThemes = [
      { section: ".theming-animate-intro-separate-ways", classes: "theme-frosted-white transparent"}, 
      { section: ".theming-animate-shift-stack", classes: "theme-frosted-white transparent"},
      { section: ".theming-animate-pin-sector-list", classes: "theme-frosted-primary"}
    ];

    return sectionThemes;
  }
}

export default FullscreenAnimated;