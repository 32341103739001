import WaterfallFeatureImages from '../../section/waterfall-feature-images/waterfall-feature-images';
import ServiceRow from '../../section/service-row/service-row';
import ProjectDetail from '../../section/project-detail/project-detail';
import LogoShowcase from '../../dynamic/logo-showcase/logo-showcase';
import ProjectMap from '../../section/project-map/project-map';
import TextTeaser from '../../simple/text-teaser/text-teaser';
import IconList from '../../dynamic/icon-list/icon-list';
import FullscreenAnimated from '../../section/fullscreen-animated/fullscreen-animated';
import Contact from '../../section/contact/contact';
import ScrollIndicatorBar from '../../simple/scroll-indicator-bar/scroll-indicator-bar';
import ImageDetail from '../../simple/image-detail/image-detail';
import ContentRow from '../../section/content-row/content-row';
import SwallowtailBanner from '../../section/swallowtail-banner/swallowtail-banner';
import HighlightGroup from '../../dynamic/highlight-group/highlight-group';
import FeaturedHighlight from '../../section/featured-highlight/featured-highlight';
import FeaturedRow from '../../section/featured-row/featured-row';
import HighlightTile from '../../simple/highlight-tile/highlight-tile';
import Hero from '../../section/hero/hero';
import ProjectsGallery from '../../section/projects-gallery/projects-gallery';
import RichText from '../../simple/rich-text/rich-text';
import ProjectTile from '../../simple/project-tile/project-tile';
import Pagination from '../../dynamic/pagination/pagination';
import GridLayout from '../../dynamic/grid-layout/grid-layout';
import Spacer from '../../simple/spacer/spacer';
import Nav from '../../dynamic/nav/nav';
import Footer from '../../section/footer/footer';
import Header from '../../section/header/header';
import ResponsiveTables from '../../base/02-content/responsiveTable';

//global scope
//used for multiple instances of the same component
var globalClasses = {};
window.classes = globalClasses;
  
//used for class to class interactions
var mainClasses = {
  Contact,
  FeaturedHighlight,
  FullscreenAnimated,
  Hero,
  Nav,
  ProjectMap,
  ResponsiveTables,
  ScrollIndicatorBar,
  WaterfallFeatureImages
};

//used for class to class interactions
var mainClassesInstances = {};

function init() {
    for (const [key, value] of Object.entries(mainClasses)) {
        mainClassesInstances[key] = new value();
    }
}

//register sections that want the navbar to be frosted when overtop
function registerThemeColor() {
    var themeSectionSelectors = [];

    for (const [key, value] of Object.entries(mainClassesInstances)) {
      if (typeof value.registerThemeColor === 'function') {
        var values = value.registerThemeColor();

        if (Array.isArray(values)) {
          themeSectionSelectors = themeSectionSelectors.concat(values);
        } else {
          themeSectionSelectors.push(values);
        }
      }
    }

    return themeSectionSelectors;
  }

init();
Header.initNavbar(registerThemeColor());

export default mainClasses; 